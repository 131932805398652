import Axios from 'axios';
import { servicePath } from '../constants/defaultValues';

export default {
    async Store(formData) {
        try {
            const response = await Axios.post(`${servicePath}postulate/store`, formData)
            return response.data;
        }
        catch (e) {
            return  e
        }
    },
}
