import Axios from 'axios';
import { servicePath } from '../constants/defaultValues';

export default {
    async Jobs() {
        try {
            const response = await Axios.get(`${servicePath}job-category/jobs`)
            return response.data;
        }
        catch (e) {
            return  e
        }

    }
}
