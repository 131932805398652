import React from 'react'
import { Link } from 'react-router-dom';

export default function Header(props) {
    return (
        <header id="header" className="header header-transparent">
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <Link className="navbar-brand" to={'/'}>
                        {
                            props.black ?
                                <img src="/img/logos/lema_footer.png" alt="logo" class="mb-30 mt-60" />
                                :
                                <img src="/img/logos/lema_blanco.png" className="logo-light" alt="logo" />
                        }
                        <img src="/img/logos/lema_menu.png" className="logo-dark" alt="logo" />
                    </Link>
                    <button className="navbar-toggler" type="button">
                        <span className="menu-lines"><span></span></span>
                    </button>
                    <div className="collapse navbar-collapse" id="mainNavigation">
                        <ul className={`navbar-nav ml-auto ${props.black ? 'text-black' : 'text-white'}`}>
                            <li className="nav__item">
                                <Link className="nav__item-link" to={'/'}>Inicio</Link>
                            </li>
                            <li className="nav__item">
                                <Link className="nav__item-link" to={'/services'}>Servicios</Link>
                            </li>
                            <li className="nav__item">
                                <Link className="nav__item-link" to={'/trucks'}>Unidades</Link>
                            </li>
                            <li className="nav__item">
                                <Link className="nav__item-link" to={'/crt'}>Certificaciones</Link>
                            </li>
                            <li className="nav__item">
                                <Link className="nav__item-link" to={'/about'}>Nosotros</Link>
                            </li>
                            <li className="nav__item">
                                <Link className="nav__item-link" to={'/jobs'}>Bolsa de trabajo</Link>
                            </li>
                            <li className="nav__item">
                                <Link className="nav__item-link " to={'/contact'}>Contacto</Link>
                            </li>
                            <li className="nav__item">
                                <Link className="nav__item-link contact-button" to={'/supplier'}>Proveedores</Link>
                                {/* <a target="_blank" className="nav__item-link contact-button" href='https://buzon2.narancia.com/LEMATRANSPORT'>Proveedores</a> */}
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <a target="_blank" href="https://web.whatsapp.com/send?phone=+524421270870" className="whats">
                <img className=" wow animate__animated animate__backInUp" src="/img/whats.png" />
            </a>
        </header>


    )
}
