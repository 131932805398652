import React from 'react'
import { Link } from 'react-router-dom';

export default function Carrers() {
    return (
        // <section className="banner-layout3 bg-overlay bg-parallax pt-120 pb-130">
        /* <div className="bg-img"><img src="/img/backgrounds/back_03.jpg" alt="background" /></div> */
        <div  className="carrers pb-130 pt-120">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="heading-layout2 heading-light mb-50">
                <span className="heading__subtitle white" >Hablemos de números.</span>
                <h2 className="heading__title white">Queremos que sumes con nosotros</h2>
              </div>
            </div>
          </div>
          <div className="row counter-light">
           
            <div className="col-6 col-sm-3">
              <div className="counter-item">
                <h4 className="counter white">20</h4>
                <p className="counter__desc white">Años de experiencia</p>
              </div>
            </div>
          
            <div className="col-6 col-sm-3">
              <div className="counter-item">
                <h4 className="counter white">153</h4>
                <p className="counter__desc white">Clientes satisfechos</p>
              </div>
            </div>
          
            <div className="col-6 col-sm-3">
              <div className="counter-item">
                <h4 className="counter white">14,583</h4>
                <p className="counter__desc white">Bienes entregados</p>
              </div>
            </div>
            <div className="col-6 col-sm-3">
              <div className="counter-item">
                <h4 className="counter white">967,583</h4>
                <p className="counter__desc white">Kilómetros recorridos</p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 text-center">
                <Link to="/contact" className="btn btn__primary btn__hover3 btn__lg mt-80  btn-info">Solicitar Servicio</Link>
            </div>
        </div>
      </div>
    )
}
