import React from 'react'
import useScript from '../hooks/UseScript';

export default function SliderTruck() {
    useScript('./js/jquery-3.3.1.min.js')
    useScript('./js/plugins.js')
    useScript('./js/main.js')
    

    return (
        <section id="sliderTruck" className="slider slider-1">
            <div className="owl-carousel thumbs-carousel carousel-arrows" data-slider-id="slider1" data-dots="false"
                data-autoplay="true" data-nav="true" data-transition="fade" data-animate-out="fadeOut" data-animate-in="fadeIn">
                <div className="slide-item align-v-h bg-overlay">
                    <div className="bg-img"><img src="img/trucks/trailer/1.png" alt="slide img" /></div>
                </div>
                <div className="slide-item align-v-h bg-overlay">
                    <div className="bg-img"><img src="img/trucks/trailer/2.png" alt="slide img" /></div>
                </div>
                <div className="slide-item align-v-h bg-overlay">
                    <div className="bg-img"><img src="img/trucks/trailer/3.png" alt="slide img" /></div>
                </div>
                <div className="slide-item align-v-h bg-overlay">
                    <div className="bg-img"><img src="img/trucks/trailer/4.png" alt="slide img" /></div>
                </div>
                <div className="slide-item align-v-h bg-overlay">
                    <div className="bg-img"><img src="img/trucks/trailer/5.png" alt="slide img" /></div>
                </div>
            </div>
        </section>
    )
}
