import React from 'react'
import { Switch, Route } from "react-router-dom";
import Home from './screens/Home'
import Service from './screens/Service'
import Trucks from './screens/Trucks'
import Crt from './screens/Crt'
import About from './screens/About'
import Jobs from './screens/Jobs'
import Contact from './screens/Contact'
import Supplier from './screens/Supplier'
import { Notifications } from 'react-push-notification';

function App() {
  return (<>
    <Notifications position='bottom-left' />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/services" component={Service} />
      <Route exact path="/trucks" component={Trucks} />
      <Route exact path="/crt" component={Crt} />
      <Route exact path="/about" component={About} />
      <Route exact path="/jobs" component={Jobs} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/supplier" component={Supplier} />
    </Switch>
  </>

  );
}

export default App;
