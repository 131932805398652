import React from 'react'
import Loader from './../components/Loader'
import Header from './../components/Header'
import SliderVideo from './../components/SliderVideo'
import Offert from './../components/Offert'
import ServicesInfo from './../components/ServicesInfo'
import ServicesMore from './../components/ServicesMore'
import BannerServices from './../components/BannerServices'
import Footer from './../components/Footer'

export default function Service() {
    return (
        <div className="wrapper">
            <Loader />
            <Header/>
            <SliderVideo/>
            <Offert/>
            <ServicesInfo/>
            <ServicesMore/>
            <BannerServices/>
            <Footer/>

        </div>
    )
}
