import React, { useRef, useState } from 'react'
import Loader from './../components/Loader'
import Slider from './../components/Slider'
import Header from './../components/Header'
import Lema from './../components/Lema'
import Services from './../components/Services'
import Tech from './../components/Tech'
import Clients from './../components/Clients'
import Carrers from './../components/Carrers'
import Footer from './../components/Footer'

export default function Home() {
    const [showInfo, setShowInfo] = useState(true);
    const [isMute, setIsMute] = useState(true);
    const myVideo = useRef(null);

    const playVideo = () => {
        setIsMute(false);
        setShowInfo(false);
        try{
            myVideo.current.play();

        }catch{
            console.log('load video')
        }
    }
    const stopVideo = () =>{
        setIsMute(!isMute);
        setShowInfo(true);
    }

    const muteVideo = () =>{
        setIsMute(!isMute);
    }

    return (
        <div className="wrapper">
            <Loader />
            <Header />
            {
                showInfo ?
                <Slider playVideo = {playVideo} />

                :
                <div className="videoPanel">
                     <video ref={myVideo} className="homeVideo" loop muted={isMute}  autoPlay>
                        <source src="videos/home.mp4" type="video/mp4" />
                    </video>
                </div>
            }
           
            {
                !showInfo && (
                    <div className="control-buttons">
                        <span className="button-home" onClick={stopVideo} ><i class="fas fa-times-circle"></i></span>
                        {
                            !isMute ? 
                            (
                                <span className="button-home" onClick={muteVideo} ><i class="fas fa-volume"></i></span>
                            ) :
                            (
                                <span className="button-home" onClick={muteVideo} ><i class="fas fa-volume-mute"></i></span>

                            )
                        }

                    </div>
                )
            }
            <Lema />
            <Services />
            <Tech />
            <Clients />
            <Carrers />
            <Footer />
        </div>
    )
}
