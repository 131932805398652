import React, { useState } from 'react'

export default function FormContact() {
  const handleDownload = () => {
    // URL de tu archivo PDF
    const pdfUrl = '/pdf/supplier/Manual_de_usuario_proveedores_Lema_2024.pdf';

    // Crear un elemento <a> temporal para iniciar la descarga
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('download', 'Manual_de_usuario_proveedores_Lema_2024.pdf');
    link.click();
  }
  return (
    <section id="contact1" className="contact text-center">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
            <div className="heading text-center mb-50">
              <small className="small-title">Proveedores</small>
              <h2 className="heading__title">Sube tu factura, evidencias y complemento de pago</h2>
              <div className="divider__line divider__theme divider__center mb-0"></div>
              <p className="heading__desc">
                Para agilizar nuestro proceso de pago, te pedimos que subas tus facturas, evidencias complementos de pago directamente a nuestra plataforma. Utiliza el siguiente enlace para acceder a la sección correspondiente y asegúrate de completar todos los campos requeridos. Esto nos ayudará a procesar tu pago de manera rápida y eficiente.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div className="col-12 d-contents">
            <div class="col-sm-12 col-md-3 col-lg-3 m-auto">
              <div class="service-item">
                <div class="service__img">
                  <img src="/img/supplier/manual.png" alt="service" class="img-supplier" />
                </div>
                <div class="service__overlay fix-overlay">
                  <h4 class="service__title">Manual de usuario</h4>
                  <p class="service__desc">Manual de usuario paso a paso para subir tus documentos.</p>
                  <a href="javascript:void(0);" class="btn btn__white btn__link btn__underlined" onClick={handleDownload}>Descargar</a>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3 m-auto">
              <div class="service-item">
                <div class="service__img">
                  <img src="/img/supplier/platform.png" alt="service" class="img-supplier" />
                </div>
                <div class="service__overlay fix-overlay">
                  <h4 class="service__title">Plataforma</h4>
                  <p class="service__desc">Accede a nuestra plataforma para subir tus documentos.</p>
                  <a target='_blank' href="https://proveedores.cytrum.com/LEMATRANSPORT" class="btn btn__white btn__link btn__underlined">Entrar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
