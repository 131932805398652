import React, { useRef, useState } from 'react'
import Loader from './../components/Loader'
import SliderCrt from './../components/SliderCrt'
import Header from './../components/Header'
import CrtInfo from './../components/CrtInfo'
import Crts from './../components/Crts'
import Footer from './../components/Footer'

export default function Crt() {
    const [showInfo, setShowInfo] = useState(true);
    const [isMute, setIsMute] = useState(true);
    const myVideo = useRef(null);

    const playVideo = () => {
        setIsMute(false);
        setShowInfo(false);
        try{
            myVideo.current.play();

        }catch{
            console.log('load video')
        }
    }
    const stopVideo = () =>{
        setIsMute(!isMute);
        setShowInfo(true);
    }

    const muteVideo = () =>{
        setIsMute(!isMute);
    }

    return (
        <div className="wrapper">
            <Loader />
            <Header/>
            {
                showInfo ?
                <SliderCrt playVideo = {playVideo} />

                :
                <div className="videoPanel">
                     <video ref={myVideo} className="homeVideo" loop muted={isMute}  autoPlay>
                        <source src="videos/certificaciones.mp4" type="video/mp4" />
                    </video>
                </div>
            }
           
            {
                !showInfo && (
                    <div className="control-buttons">
                        <span className="button-home" onClick={stopVideo} ><i class="fas fa-times-circle"></i></span>
                        {
                            !isMute ? 
                            (
                                <span className="button-home" onClick={muteVideo} ><i class="fas fa-volume"></i></span>
                            ) :
                            (
                                <span className="button-home" onClick={muteVideo} ><i class="fas fa-volume-mute"></i></span>

                            )
                        }

                    </div>
                )
            }
            <CrtInfo/>
            <Crts/>
            <Footer/>
        </div>
    )
}
