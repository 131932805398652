import React, { useState } from 'react'
import Axios from 'axios'
import * as Yup from 'yup'
import { Formik, Field, Form } from 'formik';


export default function FormContact() {
  const token = 'd2b8cf330b068a0ccc64402b1dfd593ff3d19f5443660066e3be64f0632879569cd170655a2c222c3403e2350d30ff4041264cb7cdea491ed38e8ab971f0d11010d9f97e05f48c1791240e423a2c4b97c28d95c4838acc453ab83b78528067e304277296015579078b4fa2d952ce51f4d26cbcbc351071a607c5ebd049a0471d';

  // const [formData, setFormData] = useState({
  //   Phone: '',
  //   Company: '',
  //   Email: '',
  //   Details: '',
  //   Name: '',
  // });

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };



  const handleSendContact = (values) => {
    console.log(values);
    Axios.post(`https://admin.lematransport.com/api/contacs`, { data: values }, config)
      .then(res => {
        if (res) {
          console.log(res)
        }
      })
      .catch((error) => {
        console.log(error)
      });
  }

  const ContactSchema = Yup.object().shape({
    name: Yup.string()
      .required('Debes ingresar tu nombre'),
    phone: Yup.number()
      .min(10, 'El número de teléfono debe contener 10 digitos')
      .required('No se puede dejar campos vacios'),
    email: Yup.string().email('Correo invalido').required('Debes ingresar tu correo electronico'),
    company: Yup.string()
      .min(1, 'Debes ingresar tu empresa')
      .required('No se puede dejar campos vacios'),
    details: Yup.string()
      .required('No se puede dejar campos vacios'),
  });

  return (
    <section id="contact1" className="contact text-center">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
            <div className="heading text-center mb-50">
              <small className="small-title">Contáctanos</small>
              <h2 className="heading__title">Solicita tu presupuesto</h2>
              <div className="divider__line divider__theme divider__center mb-0"></div>
              <p className="heading__desc">Las negociaciones en curso garantizan que siempre tengamos el espacio de carga que necesitamos y la capacidad de ofrecerle tarifas competitivas, incluso durante la temporada alta.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
            {/* <div> form */}
            <div>
              <Formik
                initialValues={{
                  name: '',
                  phone: '',
                  email: '',
                  company: '',
                  details: '',
                }}
                validationSchema={ContactSchema}
                onSubmit={values => handleSendContact(values)}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="row">
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group">
                          <Field name="name" className="form-control" placeholder="Nombre" />
                          {errors.name && touched.name ? (
                            <div style={{ color: 'red' }}>{errors.name}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group">
                          <Field name="phone" className="form-control" placeholder="Numero de telefono" />
                          {errors.phone && touched.phone ? (
                            <div style={{ color: 'red' }}>{errors.phone}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">

                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group">
                          <Field name="email" className="form-control" placeholder="Correo electronico" />
                          {errors.email && touched.email ? (
                            <div style={{ color: 'red' }}>{errors.email}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group">
                          <Field name="company" className="form-control" placeholder="Empresa" />
                          {errors.company && touched.company ? (
                            <div style={{ color: 'red' }}>{errors.company}</div>
                          ) : null}</div>
                      </div>

                    </div>


                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="form-group">
                        <Field name="details" className="form-control" placeholder="Detalles" />
                        {errors.details && touched.details ? (
                          <div style={{ color: 'red' }}>{errors.details}</div>
                        ) : null}</div>
                    </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <button className="btn btn__primary btn__hover3"  type="submit">Enviar Mensaje</button>
                  </div>
                  </Form>
                )}

                {/* <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group"><input type="text" onChange={handleChangeForm('name')} className="form-control" placeholder="Nombre" required /></div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group"><input type="email" onChange={handleChangeForm('email')} className="form-control" placeholder="Email" required /></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group"><input type="text" onChange={handleChangeForm('phone')} className="form-control" placeholder="Teléfono" /></div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group"><input type="text" onChange={handleChangeForm('company')} className="form-control" placeholder="Empresa" /></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <textarea className="form-control" onChange={handleChangeForm('details')} placeholder="Pedir detalles*" required></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <button onClick={handleSendContact} className="btn btn__primary btn__hover3">Enviar Mensaje</button>
                  </div>
                </div> */}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
