import React, { useEffect, useState } from 'react'
import Loader from './../components/Loader'
import SliderJob from './../components/SliderJob'
import Header from './../components/Header'
import Careers from '../components/Careers'
import Footer from './../components/Footer'
import connectOffer from '../Services/Job';


export default function Jobs() {
    const [data, setData] = useState([]);

    async function fetchData() {
        connectOffer.Jobs()
            .then((res) => {
                setData(res.data);
            });
    }
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div className="wrapper">
            <Loader />
            <Header />
            <SliderJob />
            <Careers data = {data} />
            <Footer />
        </div>
    )
}
