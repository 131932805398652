import React from 'react'

export default function JobItem(props) {
    const {data, handleShow} = props;

    return (
        <div className=" job-item">
            <div className="job__meta"><span className="job__type">{data.time?.label}</span><span className="job__location"> {data.state},{data.city} </span></div>
            <h4 className="job__title">{data.title}</h4>
            <p className="job__desc"> {data.description} </p>
            <button href="#" className="btn btn__secondary btn__link btn__underlined" variant="primary" onClick={() => handleShow(data.id)} >Postularme</button>
        </div>
    )
}
