import React from 'react'

export default function Lema() {
    return (
        <section id="about4" className="about about-2 about-4 pb-40">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-9 col-lg-5">
              <div className="">
                <img src="/img/photos/home_bg_operador.jpg" alt="about img" className="img-fluid" />
                
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-7">
              <div className="row heading heading-2">
                <div className="col-sm-12 col-md-8 col-sm-12">
                    <small className="small-title">Nuestro lema</small>
                  <h2 className="heading__title">La fuerza está en nuestra gente</h2>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-12">
                  <p className="heading__desc mb-30">En Lema Transport , es importante la experiencia de cada uno de nuestros clientes respecto al servicio.</p>
                  <p className="heading-desc">De ahí nace la infraestructura y estrategia para llevar en tiempo y forma su carga a cualquier parte de la República Mexicana.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}
