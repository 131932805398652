import React, { useState, useRef } from 'react';
import * as Yup from 'yup'
import { Formik, Field, Form } from 'formik';
import { Button, Modal, } from 'react-bootstrap';
import connectPostulate from '../Services/Postulate'
import Dropzone from '../components/Dropzone';
import addNotification from 'react-push-notification';

export default function ModalJob(props) {
    const formRef = useRef()
    const { show, handleClose, jobID } = props;

    const [formData] = useState({
        job_id: -1,
        name: '',
        last_name: '',
        phone: '',
        email: '',
        file: null,
    });

    const PostulateSchema = Yup.object().shape({
        name: Yup.string()
            .required('Debes ingresar tu nombre'),
        last_name: Yup.string()
            .required('Debes ingresar tu nombre'),
        email: Yup.string().email('Correo invalido').required('Debes ingresar tu correo electronico'),
        phone: Yup.string()
            .required('Debes ingresar tu celular'),
        file: Yup.mixed().required('Debes adjuntar tu CV/Solicitud de trabajo'),
    });

    const rebuildData = (values) => {
        let formData = new FormData();
        Object.keys(values).forEach(key => {
            formData.append(key, values[key]);
        });
        return formData;
    };

    const onSendCV = (values, { resetForm }) => {
        values.job_id = jobID;
        console.log(values);
        const data = rebuildData(values);
        connectPostulate.Store(data).then((res) => {
            if (res.status) {
                resetForm();
                handleClose();
                addNotification({
                    duration: 1000000,
                    title: res.title,
                    message: res.subtitle,
                    theme: 'darkblue',
                    native: false // when using native, your OS will handle theming.
                });
            }
        });
    };

    const handleSubmit = () => {
        if (formRef.current) {
          formRef.current.handleSubmit()
        }
      }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Envíanos tus datos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    innerRef={formRef}
                    initialValues={formData}
                    validationSchema={PostulateSchema}
                    onSubmit={onSendCV}
                >
                    {({ errors, touched, setFieldValue }) => (
                        <Form encType="multipart/form-data">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <Field name="name" className="form-control" placeholder="Nombre" />
                                        {errors.name && touched.name ? (
                                            <div style={{ color: 'red' }}>{errors.name}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <Field name="last_name" className="form-control" placeholder="Apellidos" />
                                        {errors.last_name && touched.last_name ? (
                                            <div style={{ color: 'red' }}>{errors.last_name}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <Field name="phone" className="form-control" placeholder="Numero de telefono" />
                                        {errors.phone && touched.phone ? (
                                            <div style={{ color: 'red' }}>{errors.phone}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <Field name="email" className="form-control" placeholder="Correo electronico" />
                                        {errors.email && touched.email ? (
                                            <div style={{ color: 'red' }}>{errors.email}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <Dropzone
                                        handleFileAdded={val => setFieldValue('file', val)}
                                        name="cover"
                                    />
                                    {errors.file && touched.file ? (
                                            <div style={{ color: 'red' }}>{errors.file}</div>
                                        ) : null}
                                </div>
                                {/* <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <input id="file" name="file" type='file' className="form-control" placeholder="CV2" onChange={(event) => {
                                            setFieldValue('file', event.currentTarget.files[0])
                                        }} />
                                         {errors.file && touched.file ? (
                                            <div style={{ color: 'red' }}>{errors.file}</div>
                                        ) : null}
                                    </div>
                                </div> */}

                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Mandar Datos
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
