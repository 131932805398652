import React from 'react'
import Loader from './../components/Loader'
import SliderContact from './../components/SliderContact'
import Header from './../components/Header'
import FormContact from './../components/FormContact'
import Footer from './../components/Footer'

export default function Contact() {
    return (
        <div className="wrapper">
            <Loader />
            <Header/>
            <SliderContact/>
            <FormContact/>
            <Footer/>
        </div>
    )
}
