import React from 'react'
import { Link } from 'react-router-dom';

export default function ContactTruck() {
    return (
        <section id="trucks" className="fancybox-layout2 pb-60">
            <div className="container">
                <div className="row">
                    <div className="center">
                        <img src="/img/photos/unidades.png" alt=""/>
                        <div class="fancybox-item fancybox-item-custom truck-desc">
                            <div class="fancybox__content">
                                <h4 class="fancybox__title fancybox-title">¿Qué unidad necesitas?</h4>
                                <p>Entendemos la importancia de abordar cada viaje de manera integral y creemos en el poder de la comunicación simple y fácil.</p>
                                <div class="col-sm-12 col-md-12 col-lg-12 text-left">
                                    <Link to="/contact" class="btn btn__primary btn__hover3 btn__lg mt-20  btn-info">Contáctanos</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
