import React from 'react'

export default function Offert() {
    return (
        <section id="about5" className="about about-2 about-4 pb-40">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-7">
                        <div className="row heading heading-2">
                            <div className="col-sm-12 col-md-12 col-sm-12">
                                <small className="small-title white">Qué ofecemos</small>
                                <h2 className="heading__title white">Tecnología Avanzada y soluciones personalizadas</h2>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-12">
                                <p className="heading__desc mb-30 white service-desc">Nos dedicamos a crear valor agregado para nuestros clientes mediante la implementación de tecnología.</p>
                                <p className="heading-desc white service-desc">Es por eso que el deseo de mejora constante es la fuerza que impulsa nuestro servicio de transporte.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-9 col-lg-5">
                        <div className="">
                            <img src="/img/photos/servicios_gps.png" alt="about img" className="img-fluid" />

                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
