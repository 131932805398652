import React from 'react'
import Loader from './../components/Loader'
import SliderAbout from './../components/SliderAbout'
import Header from './../components/Header'
import AboutTeam from './../components/AboutTeam'
import AboutInfo from './../components/AboutInfo'
import Footer from './../components/Footer'

export default function About() {
    return (
        <div className="wrapper">
            <Loader />
            <Header/>
            <SliderAbout/>
            <AboutInfo/>
            <AboutTeam/>
            <Footer/>
        </div>
    )
}
