import React from 'react'
import Loader from './../components/Loader'
import FormSupplier from './../components/FormSupplier'
import SliderSupplier from './../components/SliderSupplier'
import Header from './../components/Header'
import Footer from './../components/Footer'

export default function Contact() {
    return (
        <div className="wrapper">
            <Loader />
            <Header/>
            <SliderSupplier />
            <FormSupplier/>
            <Footer/>
        </div>
    )
}
