import React from 'react'
import { Link } from 'react-router-dom';

export default function ServicesInfo() {
    return (
        <section id="background6" className="about about-2 about-4 pb-40">
            <div className="container">
                <div className="row service-panel">
                    <div className="col-sm-12 col-md-4 col-lg-4 pt-40">
                        <div className="row heading heading-2">
                            <div className="col-sm-12 col-md-12 col-sm-12 mb-20">
                                <small className="small-title ">Transporte de carga</small>
                                <h2 className="heading__title heading-title-service">Consolidada</h2>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-12">
                                <p className="heading__desc mb-30  service-desc">No ocuparemos un camión completo para transportar tu carga, por lo que te permitirá ahorrar costos al ser un envío compartido entre varios</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 left-btn">
                            <Link to="/contact" className="btn btn__primary btn__hover3 btn__lg mt-10  btn-info">Solicitar Servicio</Link>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 pt-40">
                        <div className="row heading heading-2">
                            <div className="col-sm-12 col-md-12 col-sm-12 mb-20">
                                <small className="small-title ">Transporte de carga</small>
                                <h2 className="heading__title heading-title-service">Dedicada</h2>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-12">
                                <p className="heading__desc mb-30  service-desc">Un camión completo para tu mercancía asegurando su traslado y envío más rápido. Servicio directo puerta a puerta, desde su salida a llegada a destino.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <img src="/img/photos/servicios_cajas.png" className="service-img"/>
                    </div>
                </div>
                <div className="row service-panel mt-80">
                    <div className="col-sm-12 col-md-4 col-lg-4 pt-40">
                        <div className="row heading heading-2">
                            <div className="col-sm-12 col-md-12 col-sm-12 mb-20">
                                <small className="small-title ">Servicio de carga</small>
                                <h2 className="heading__title heading-title-service">Sobredimensionada</h2>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-12">
                                <p className="heading__desc mb-30  service-desc">Si necesita transportar equipo especializado o maquinaria de forma nacional o internacional, requieres de un manejo especial en cama bajas o lowboy</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 left-btn">
                            <Link to="/contact" className="btn btn__primary btn__hover3 btn__lg mt-10  btn-info">Solicitar Servicio</Link>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 pt-40">
                        <div className="row heading heading-2">
                            <div className="col-sm-12 col-md-12 col-sm-12 mb-20">
                                <small className="small-title ">Servicio de carga</small>
                                <h2 className="heading__title heading-title-service">Doble contenedor</h2>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-12">
                                <p className="heading__desc mb-30  service-desc">El volumen de tu carga no es problema, ofrecemos circulación de servicio para doble remolque.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <img src="/img/photos/servicios_cajas.png" className="service-img"/>
                    </div>
                </div>
            </div>
        </section>
    )
}
