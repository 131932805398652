import React, { useState } from 'react';
import JobItem from './JobItem';
import ModalJob from './ModalJob';

export default function Careers(props) {
    const {data} = props;
    const [showModalPostulate, setShowModaPostulate] = useState(false);
    const [selectJobID, setSelectJobID] = useState(-1);

    const handleShow = (id) =>{
        setSelectJobID(id);
        setShowModaPostulate(true)

    }
    const handleClose = () =>{
        setShowModaPostulate(false)
    }

    return (
        <section id="careers" class="careers bg-gray">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
                        <div class="heading text-center mb-50">
                            <span class="heading__subtitle small-title">Trabaja con nosotros</span>
                            <h2 class="heading__title">Vacantes abiertas</h2>
                            <p>Las marcas más importante del mundo no pueden existir sin personas talentosas como tú. En LOGÍSTICA LEMA DE QUERÉTARO S DE RL DE CV,
                                creemos que la calidad, la agilidad, el compromiso y la responsabilidad crean una cultura de innovación y crecimiento. </p>
                            <div class="divider__line divider__center"></div>
                        </div>
                    </div>
                </div>
                {
                    data?.map((element)=> <div key={element.id} class="row">
                        <div class="row heading mb-40">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <h2 class="heading__title">{element.name}</h2>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="carousel owl-carousel owl-loaded owl-drag" data-slide="3" data-slide-md="2" data-slide-sm="1" data-autoplay="true" data-nav="false" data-dots="false" data-space="30" data-loop="true" data-speed="800">
                                <div class="owl-stage-outer">
                                    <div class="owl-stage">
                                        <div class="owl-item cloned" >
                                            <div className="row">
                                                {
                                                    element.jobs.map((job)=>
                                                        <div key={job.id} class="col-sm-12 col-md-4 col-lg-4 mb-4">
                                                            <JobItem data = {job} handleShow = {handleShow} />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="owl-nav disabled"><button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button></div>
                                <div class="owl-dots disabled"></div>
                                <div class="owl-thumbs"></div>
                            </div>
                        </div>
                    </div>)
                }
            </div>
            <ModalJob show = {showModalPostulate}  handleClose = {handleClose} jobID = {selectJobID} />
        </section>
    )
}
