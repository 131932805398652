import React from 'react'
import Loader from './../components/Loader'
import SliderTruck from './../components/SliderTruck'
import Header from './../components/Header'
import Footer from './../components/Footer'
import TrucksInfo from '../components/TrucksInfo'
import ContactTruck from '../components/ContactTruck'

export default function Trucks() {
    return (
        <div className="wrapper">
            <Loader />
            <Header/>
            <SliderTruck/>
            <TrucksInfo/>
            <ContactTruck/>
            <Footer/>
        </div>
    )
}
