import React from 'react'

export default function Slider(props) {
    return (
      <section  class="slider slider-1">
      <div class="">
         <div class="slide-item align-v-h bg-overlay slider-home" >
            <div class="container">
               <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-8">
                     <div class="slide__content">
                        <h2 class="slide__title">Apoyamos la industria y el comercio mexicano</h2>
                        <span onClick={props.playVideo} class="btn btn__video popup-video" >
                           <div class="video__player"><span class="video__player-animation"></span><i class="fa fa-play"></i></div>
                           Ver más...
                        </span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="container">
         <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12  d-none d-lg-block">
               <div class="owl-thumbs thumbs-dots" ><button class="owl-thumb-item"><img src="/img/logos/ctpat.png" /></button></div>
            </div>
         </div>
      </div>
   </section>
    )
}
