import React from 'react'

export default function SliderAbout() {
    return (
      <section  class="slider slider-1">
      <div class="">
         <div class="slide-item align-v-h bg-overlay slider-about" >
            <div class="container">
               <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-10">
                     <div class="slide__content">
                        <h2 class="slide__title">Somos colaboradores que estamos comprometidos con nuestros clientes</h2>
                        <p className="sub-service">Contamos con ventajas competitivas</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
    )
}
